<template>
  <b-button :disabled="disabled" :class="[buttonClass, { 'inactive': disabled }]" @click="onClick">
    <slot name="svg"></slot>
    <div class="text"> {{ buttonText }}</div>
  </b-button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    buttonClass() {
      return this.primary ? 'btn-primary' : this.secondary ? 'btn-secondary' : '';
    }
  }
}
</script>

<style scoped>
button {
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--margin-s) var(--margin-l);
}
button.disabled {
  padding: var(--margin-s) var(--margin-l) !important;
}
.btn-secondary {
  background-color: var(--color-gray-200);
  border: none;
}

.inactive {
  opacity: 0.4 !important;
}
</style>