<template>
  <svg :style="transformStyle" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" :fill="color">
    <mask id="mask0_37608_1037" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_37608_1037)">
      <path
        d="M7.49577 18.3327L6.0166 16.8535L12.8708 9.99935L6.0166 3.14518L7.49577 1.66602L15.8291 9.99935L7.49577 18.3327Z"
        :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    orientation: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#0CBA4A'
    },
    width: {
      type: [String, Number],
      default: 20
    },
    height: {
      type: [String, Number],
      default: 20
    }
  },
  computed: {
    transformStyle() {
      return `transform: rotate(${this.orientation}deg)`;
    }
  }
}
</script>

<style lang="scss" scoped></style>