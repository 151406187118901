<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_38373_9933" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_38373_9933)">
      <path
        d="M8.00065 14.6654C7.07843 14.6654 6.21176 14.4904 5.40065 14.1404C4.58954 13.7904 3.88398 13.3154 3.28398 12.7154C2.68398 12.1154 2.20898 11.4098 1.85898 10.5987C1.50898 9.78759 1.33398 8.92092 1.33398 7.9987C1.33398 7.07648 1.50898 6.20981 1.85898 5.3987C2.20898 4.58759 2.68398 3.88203 3.28398 3.28203C3.88398 2.68203 4.58954 2.20703 5.40065 1.85703C6.21176 1.50703 7.07843 1.33203 8.00065 1.33203C8.92287 1.33203 9.78954 1.50703 10.6007 1.85703C11.4118 2.20703 12.1173 2.68203 12.7173 3.28203C13.3173 3.88203 13.7923 4.58759 14.1423 5.3987C14.4923 6.20981 14.6673 7.07648 14.6673 7.9987C14.6673 8.92092 14.4923 9.78759 14.1423 10.5987C13.7923 11.4098 13.3173 12.1154 12.7173 12.7154C12.1173 13.3154 11.4118 13.7904 10.6007 14.1404C9.78954 14.4904 8.92287 14.6654 8.00065 14.6654ZM8.00065 13.332C8.60065 13.332 9.17843 13.2348 9.73398 13.0404C10.2895 12.8459 10.8007 12.5654 11.2673 12.1987L3.80065 4.73203C3.43398 5.1987 3.15343 5.70981 2.95898 6.26536C2.76454 6.82092 2.66732 7.3987 2.66732 7.9987C2.66732 9.48759 3.18398 10.7487 4.21732 11.782C5.25065 12.8154 6.51176 13.332 8.00065 13.332ZM12.2007 11.2654C12.5673 10.7987 12.8479 10.2876 13.0423 9.73203C13.2368 9.17648 13.334 8.5987 13.334 7.9987C13.334 6.50981 12.8173 5.2487 11.784 4.21536C10.7507 3.18203 9.48954 2.66536 8.00065 2.66536C7.40065 2.66536 6.82287 2.76259 6.26732 2.95703C5.71176 3.15148 5.20065 3.43203 4.73398 3.7987L12.2007 11.2654Z"
        :fill="fill" />
    </g>
  </svg></template>

<script>
export default {
  name: 'HideIcon',
  props: {
    width: {
      type: [String, Number],
      default: 16
    },
    height: {
      type: [String, Number],
      default: 16
    },
    fill: {
      type: String,
      default: '#fff'  // blue
    }
  }
}
</script>

<style lang="scss" scoped></style>