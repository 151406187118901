<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_37664_1455" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_37664_1455)">
      <path
        d="M9.16667 13.3333V5.66667L6.16667 8.66667L5 7.5L10 2.5L15 7.5L13.8333 8.66667L10.8333 5.66667V13.3333H9.16667Z"
        fill="white" />
      <path d="M3.53857 17.5V15.625H16.4617V17.5H3.53857Z" fill="white" />
    </g>
  </svg>


</template>

<script>
export default {
  name: 'SubmitIcon',
}
</script>

<style lang="scss" scoped></style>