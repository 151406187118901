<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon Item">
      <mask id="mask0_37664_7274" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_37664_7274)">
        <path id="published_with_changes"
          d="M9.1665 18.2923C8.11095 18.1812 7.12831 17.893 6.21859 17.4277C5.30887 16.9625 4.5172 16.3583 3.84359 15.6152C3.16998 14.8722 2.63873 14.0215 2.24984 13.0632C1.86095 12.1048 1.6665 11.084 1.6665 10.0007C1.6665 8.73676 1.91998 7.5701 2.42692 6.50065C2.93387 5.43121 3.62484 4.51454 4.49984 3.75065H2.49984V2.08398H7.49984V7.08398H5.83317V4.81315C5.06928 5.42426 4.46164 6.17773 4.01025 7.07357C3.55887 7.9694 3.33317 8.9451 3.33317 10.0007C3.33317 11.709 3.8922 13.1847 5.01025 14.4277C6.12831 15.6708 7.51373 16.3965 9.1665 16.6048V18.2923ZM8.81234 13.834L5.2915 10.2923L6.45817 9.12565L8.81234 11.4798L13.5415 6.75065L14.7082 7.93815L8.81234 13.834ZM12.4998 17.9173V12.9173H14.1665V15.1882C14.9304 14.5632 15.538 13.8062 15.9894 12.9173C16.4408 12.0284 16.6665 11.0562 16.6665 10.0007C16.6665 8.29232 16.1075 6.81662 14.9894 5.57357C13.8714 4.33051 12.4859 3.60482 10.8332 3.39648V1.70898C12.9443 1.91732 14.7221 2.80621 16.1665 4.37565C17.6109 5.9451 18.3332 7.8201 18.3332 10.0007C18.3332 11.2645 18.0797 12.4312 17.5728 13.5007C17.0658 14.5701 16.3748 15.4868 15.4998 16.2507H17.4998V17.9173H12.4998Z"
          fill="white" />
      </g>
    </g>
  </svg>


</template>

<script>
export default {
  name: 'SyncIcon',
}
</script>

<style lang="scss" scoped></style>