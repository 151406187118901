<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_37664_8245" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_37664_8245)">
      <path
        d="M9.99984 13.7493C9.86543 13.7493 9.73774 13.7247 9.61677 13.6754C9.49581 13.6261 9.38604 13.5522 9.28747 13.4537L5.55091 9.71709C5.35378 9.51996 5.25745 9.28698 5.26193 9.01817C5.26641 8.74935 5.36274 8.51637 5.55091 8.31924C5.74805 8.12211 5.98326 8.0213 6.25656 8.01682C6.52986 8.01234 6.76507 8.10867 6.9622 8.3058L8.99177 10.3354V2.67408C8.99177 2.3963 9.09034 2.15885 9.28747 1.96171C9.4846 1.76458 9.72206 1.66602 9.99984 1.66602C10.2776 1.66602 10.5151 1.76458 10.7122 1.96171C10.9093 2.15885 11.0079 2.3963 11.0079 2.67408V10.3354L13.0375 8.3058C13.2346 8.10867 13.4698 8.01234 13.7431 8.01682C14.0164 8.0213 14.2516 8.12211 14.4488 8.31924C14.6369 8.51637 14.7333 8.74935 14.7377 9.01817C14.7422 9.28698 14.6459 9.51996 14.4488 9.71709L10.7122 13.4537C10.6136 13.5522 10.5039 13.6261 10.3829 13.6754C10.2619 13.7247 10.1342 13.7493 9.99984 13.7493ZM3.68263 18.3327C3.12708 18.3327 2.65217 18.1356 2.2579 17.7413C1.86364 17.347 1.6665 16.8721 1.6665 16.3166V14.166C1.6665 13.8882 1.76507 13.6508 1.9622 13.4537C2.15934 13.2565 2.39679 13.158 2.67457 13.158C2.95235 13.158 3.1898 13.2565 3.38693 13.4537C3.58407 13.6508 3.68263 13.8882 3.68263 14.166V16.3166H16.317V14.166C16.317 13.8882 16.4156 13.6508 16.6127 13.4537C16.8099 13.2565 17.0473 13.158 17.3251 13.158C17.6029 13.158 17.8403 13.2565 18.0375 13.4537C18.2346 13.6508 18.3332 13.8882 18.3332 14.166V16.3166C18.3332 16.8721 18.136 17.347 17.7418 17.7413C17.3475 18.1356 16.8726 18.3327 16.317 18.3327H3.68263Z"
        fill="white" />
    </g>
  </svg>

</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>