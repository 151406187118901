<template>
  <b-modal :id="modalId" :size="size" @show="resetModal" @hidden="resetModal">
    <template #modal-title>
      <slot name="title"></slot>
    </template>

    <template #default>
      <slot></slot>
    </template>
    <template #modal-footer>
      <slot name="footer"></slot>
     
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'BasicModal',

  props: {
    modalId: String,
    cancelButtonText: {
      type: String,
      default: 'Abbrechen'
    },
    confirmButtonText: {
      type: String,
      default: 'Bestätigen'
    },
    size: {
      type: String,
      default: 'xl'
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(this.modalId)
    },
    hideModal() {
      this.$bvModal.hide(this.modalId)
    },
    resetModal() {
      this.$emit('reset')
    },
    handleSubmit() {
      console.log('handleSubmit')
    }
  },
  created() {
  }
}
</script>
<style scoped>
  .modal-body {
    padding: var(--margin-l);
  }
  

@media (max-height: 880px) {

  .modal-body {
    padding: var(--margin-l);
  }
}
</style>