<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <mask id="mask0_38337_14459" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
    <rect width="16" height="16" fill="black"/>
  </mask>
  <g mask="url(#mask0_38337_14459)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.39967 14.1404C6.21079 14.4904 7.07745 14.6654 7.99967 14.6654C8.22522 14.6654 8.44744 14.6549 8.66634 14.634V14.6324C8.88097 14.6111 9.0926 14.5796 9.30075 14.5385L9.042 13.2305C8.9184 13.2549 8.79314 13.2751 8.66634 13.2909V2.70649C8.79314 2.72226 8.9184 2.74246 9.042 2.76691L9.30075 1.45892C9.0926 1.41775 8.88097 1.38626 8.66634 1.36495V1.36343C8.44744 1.3425 8.22522 1.33203 7.99967 1.33203C7.07745 1.33203 6.21079 1.50703 5.39967 1.85703C4.58856 2.20703 3.88301 2.68203 3.28301 3.28203C2.68301 3.88203 2.20801 4.58759 1.85801 5.3987C1.50801 6.20981 1.33301 7.07648 1.33301 7.9987C1.33301 8.92092 1.50801 9.78759 1.85801 10.5987C2.20801 11.4098 2.68301 12.1154 3.28301 12.7154C3.88301 13.3154 4.58856 13.7904 5.39967 14.1404ZM2.75774 9.04303L2.76789 9.04102C2.70141 8.70498 2.66634 8.35659 2.66634 7.9987C2.66634 7.6408 2.70141 7.29242 2.76789 6.95638L2.75774 6.95437C2.88569 6.25152 3.14801 5.6079 3.54469 5.02352L3.56386 5.03635C3.95362 4.45417 4.45515 3.95265 5.03733 3.56289L5.02449 3.54371C5.60888 3.14703 6.25249 2.88472 6.95535 2.75676L6.95735 2.76691C7.08095 2.74246 7.20621 2.72226 7.33301 2.70649V13.2909C7.20621 13.2751 7.08095 13.2549 6.95735 13.2305L6.95535 13.2406C6.25249 13.1127 5.60888 12.8504 5.02449 12.4537L5.03733 12.4345C4.45515 12.0447 3.95362 11.5432 3.56386 10.961L3.54469 10.9739C3.14801 10.3895 2.88569 9.74588 2.75774 9.04303Z" fill="#FF6F00"/>
    <path d="M13.2315 9.04102L14.5394 9.29978C14.6227 8.87897 14.6663 8.44393 14.6663 7.9987C14.6663 7.55346 14.6227 7.11842 14.5394 6.69762L13.2315 6.95638C13.2979 7.29242 13.333 7.6408 13.333 7.9987C13.333 8.35659 13.2979 8.70498 13.2315 9.04102Z" fill="#FF6F00"/>
    <path d="M12.4355 5.03635L13.5434 4.29459C13.0567 3.56758 12.4308 2.94165 11.7038 2.45493L10.962 3.56289C11.5442 3.95265 12.0457 4.45417 12.4355 5.03635Z" fill="#FF6F00"/>
    <path d="M10.962 12.4345L11.7038 13.5425C12.4308 13.0557 13.0567 12.4298 13.5434 11.7028L12.4355 10.961C12.0457 11.5432 11.5442 12.0447 10.962 12.4345Z" fill="#FF6F00"/>
  </g>
</svg>


</template>

<script>
export default {
  name: 'IncompleteIcon',
}
</script>

<style lang="scss" scoped></style>