<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_38373_14436" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_38373_14436)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66684 5.98155V13.3316C4.66684 13.6982 4.79739 14.0121 5.05851 14.2732C5.31962 14.5343 5.63351 14.6649 6.00017 14.6649H11.3335L11.3335 14.6649H13.3168C13.6835 14.6649 14.0002 14.5371 14.2668 14.2815C14.5335 14.026 14.6668 13.7149 14.6668 13.3482V11.3316L14.6668 11.3316V5.99822C14.6668 5.63155 14.5363 5.31766 14.2752 5.05655C14.0141 4.79544 13.7002 4.66489 13.3335 4.66489H6.01684C5.65017 4.66489 5.33351 4.79266 5.06684 5.04822C4.80017 5.30377 4.66684 5.61489 4.66684 5.98155ZM1.56684 3.17322C1.35573 3.46766 1.28351 3.79822 1.35017 4.16489L2.63351 11.3982C2.66684 11.6093 2.74739 11.7982 2.87517 11.9649C3.00295 12.1316 3.15573 12.2649 3.33351 12.3649V5.99822C3.33351 5.26489 3.59462 4.63711 4.11684 4.11489C4.63906 3.59266 5.26684 3.33155 6.00017 3.33155H11.3668L11.2002 2.43155C11.1335 2.06489 10.953 1.776 10.6585 1.56489C10.3641 1.35377 10.0335 1.28155 9.66684 1.34822L2.43351 2.63155C2.06684 2.69822 1.77795 2.87877 1.56684 3.17322Z" :fill="fill"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DuplicateIcon',
  props: {
    width: {
      type: [String, Number],
      default: 16
    },
    height: {
      type: [String, Number],
      default: 16
    },
    fill: {
      type: String,
      default: '#0362E8'  // blue
    }
  }
}
</script>

<style lang="scss" scoped></style>