<template>
  <div @click="click" class="font-14 rounded-sm bg-300 d-flex align-center justify-between p-s mb-xs cursor-pointer" :style="value == 0 ? 'opacity:0.4; cursor:auto; padding-right: 52px':''">
    <div><slot></slot>{{ label }}</div>
    <div
      class="font-14 color-150">{{ value }}
      <ArrowIcon v-if="value>0" class="ml-s" :orientation="0" width="16" height="16" color="#e6e1f3" />
    </div>

  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/ArrowIcon.vue';

export default {
  name: 'InventoryStatsRow',
  components: {
    ArrowIcon
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    clickFunction: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    click() {
      if (this.value > 0)
      this.clickFunction();
    }
  }
}
</script>

<style lang="scss" scoped></style>